<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list" />
                    Список
                </b-button>
                <b-button to="../add">
                    <b-icon icon="plus" />
                    Добавить
                </b-button>
                <poster
                    tag="b-button"
                    :item-id="item.id"
                    method="admin.smsTemplate.delete"
                    :disabled="!item"
                    success-message="Данные удалены"
                    @complete="onDeleted"
                >
                    <b-icon icon="trash" />
                    Удалить
                </poster>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item || request">
            <b-form
                autocomplete="off"
                @submit="submit"
            >
                <b-form-group
                    label="Название"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text"
                        placeholder="Введите название"
                        required
                        :state="errors.name ? false : null"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.name"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Текст сообщения"
                    label-for="textarea"
                >
                    <b-form-textarea
                        id="textarea"
                        v-model="form.value"
                        placeholder="Введите текст сообщения"
                        rows="3"
                        max-rows="6"
                    />
                </b-form-group>

                <b-button
                    type="submit"
                    variant="primary"
                >
                    Сохранить
                </b-button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item: null,
            form: {
                id: null,
                name: null,
                value: null,
            },
            errors: {},
            request: false,
        }
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        submit(event) {
            event.preventDefault();

            if (this.request) {
                return;
            }

            this.request = true;

            this
                .jsonRpc("admin.smsTemplate.edit", {...this.form})
                .then(async () => {
                    this.$alertify.success("Данные успешно изменены");
                    this.$router.push({path: "../list"});
                })
                .catch(async ({error}) => {
                    if (this.$formValidation.isValidationRpcResponse(error)) {
                        this.errors = this.$formValidation.parse(error.data);
                    }
                })
                .finally(async () => {
                    this.request = false;
                });

        },
        onDeleted() {
            this.$router.push({path: "../list"});
        },
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.jsonRpc("admin.smsTemplate.get", {id}).then(async (response) => {
                this.item = response;
                for (let i in this.item) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.form.hasOwnProperty(i) && this.item.hasOwnProperty(i)) {
                        // noinspection JSUnfilteredForInLoop
                        this.form[i] = this.item[i];
                    }
                }
            })
        },
    }
}
</script>
